table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    margin-right: auto;
    margin-left: auto;
}
 
table th, table td {
    text-align: left;
    padding: 16px;
    border: 1px solid #ddd;
    font-size: 13px;
}
 
table tr:nth-child(even) {
    background-color: #f2f2f2;
}
 
table tr:hover {
    background-color: #ddd;
}
 
table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #383838;
    color: white;
    font-size: 14px;
}