#edit{
  background: #FBBD08 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
#edit:hover{
  filter: brightness(1.2);
  color: rgba(0, 0, 0, 0.87) !important;
}


#delete{
  background: #DB2828 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
#delete:hover{
  filter: brightness(1.2);
  color: rgba(0, 0, 0, 0.87) !important;
}

#create{
  background: #21BA45 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
#create:hover{
  filter: brightness(1.2);
  color: rgba(0, 0, 0, 0.87) !important;
}